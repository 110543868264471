
import Http from './Http'

export default {
    async getNews(limit = 1000, lang= '') {
        return await Http.get(`/content/news?limit=${limit}&lang=${lang}`);
    },
    async getNewsLanguage() {
        return await Http.get(`/content/news/lang`)
    },
    async removeNews(id) {
        return await Http.delete(`/content/news/${id}`)
    },
    async getVideos(limit = 1000) {
        return await Http.get(`/content/videos?limit=${limit}&all`)
    },
    async getShorts(){
        return await Http.get(`/content/shorts`)
    },
    async removeShorts(id){
       return await Http.delete(`/content/shorts/${id}`)
    },
    async removeVideos(id) {
        return await Http.delete(`/content/videos/${id}`)
    },
    async fireEvent(data) {
        return await Http.post(`/content/fireEvent`, data)
    },
    async addBusinessNews(data) {
        return await Http.post(`/business/news`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async getBusinessNews(limit = 1000,active = 'all',content_type) {
        return await Http.get(`/business/news?limit=${limit}&active=${active}&content_type=${content_type}`)
    },
    async deleteBusinessNews(news_id) {
        return await Http.delete(`/business/news/${news_id}`)
    },
    async editBusinessNews(news_id, data){
        return await Http.post(`/business/news/${news_id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async getNewsFeed(lang= ''){
        return await Http.get(`/content/feed?lang=${lang}`)
    },
    async updateFeed(id, data){
        return await Http.post(`/content/feed/${id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })

    },
}


